import { UTCDate } from "@date-fns/utc";
import { format, getYear, isBefore, parse, parseISO, startOfDay } from "date-fns";
import { dateFormatISO } from "../components/CalendarPicker/CalendarConstants";
import currentLocale from "./locales";

export const DATE_MAX = new Date(8.64e15);
export const DATE_MIN = new Date(-8.64e15);

const browserCompatibleParse = (date: string | null | undefined): Date => {
  // see: https://github.com/date-fns/date-fns/issues/2130#issuecomment-827836812
  return parse("", "", date || "");
};

export const formatDmy = (date: string | null | undefined) => {
  if (!date) {
    return date;
  }
  format(browserCompatibleParse(date), "dd.MM.yyyy", { locale: currentLocale() });
};

export function ticksToDate(ticks: number): UTCDate {
  return new UTCDate(new Date("0001-01-01T00:00:00Z").getTime() + ticks / 1e4);
}

export function toStringWithFullMonth(date: string | null | undefined): string | null | undefined {
  if (!date) {
    return date;
  }
  return format(browserCompatibleParse(date), "d. MMM", { locale: currentLocale() });
}

export function formatISOWithoutTimestamp(
  date: Date | string | null | undefined,
): string | null | undefined {
  if (!date) {
    return date;
  }
  let _date = date;
  if (typeof date === "string") {
    _date = parseISO(date);
  }
  return format(_date, dateFormatISO);
}

/**
 * Returns the fiscal year date as a number.
 * The fiscal year starts on April 1st and ends on March 31st of the following year.
 *
 * @returns {number} The fiscal year as a number.
 */
export function hentJaktaar(date: Date = new Date()): number {
  const currentYear = getYear(date);
  const fiscalYearStart = new Date(currentYear, 3, 1); // April 1 of the current year

  let fiscalStartYear: number;

  if (isBefore(startOfDay(date), startOfDay(fiscalYearStart))) {
    fiscalStartYear = currentYear - 1;
  } else {
    fiscalStartYear = currentYear;
  }

  return fiscalStartYear;
}
