import { isAfter, isBefore, isValid, parse, startOfDay } from "date-fns";

import { t } from "i18next";
import { useCallback, useEffect, useRef } from "react";
import { dateFormatNorsk } from "../components/CalendarPicker/CalendarConstants";
import { hentJaktaar } from "../helpers/time";
import { Stateful, useStateful } from "./useStateful";

/**
 * `useCalendarHandlers` is a custom hook that handles the logic for a calendar input field.
 *
 * @param {Stateful<Date>} date - A stateful value representing the currently selected date.
 * @param {Stateful<boolean>} showCalendar - A stateful value representing whether the calendar is currently shown.
 *
 * @returns {Object} An object containing the following properties:
 * - `inputRef`: A ref to the input element.
 * - `inputValid`: A stateful value representing whether the current input value is a valid date.
 * - `handleOpen`: A function that toggles the visibility of the calendar.
 * - `handleSelect`: A function that updates the selected date and hides the calendar.
 * - `handleInputChange`: A function that validates the input value and updates the date and input validity accordingly.
 * - `inputValue`: A stateful value representing the current input value.
 * - `errorMessage`: A stateful value containing validation error text.
 */
export default function useCalendarHandlers(date: Stateful<Date>, showCalendar: Stateful<boolean>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputValid = useStateful<boolean>(false);
  const inputValue = useStateful<string>("");
  const errorMessage = useStateful<string>("");

  // Trigger once on page load
  useEffect(() => {
    handleInputChange();
  }, []);

  const validateDate = useCallback(
    (dateToValidate: Date) => {
      const today = startOfDay(new Date());
      const firstOfApril = new Date(hentJaktaar(), 3, 1);
      if (!isAfter(dateToValidate, today) && !isBefore(dateToValidate, firstOfApril)) {
        date.set(dateToValidate);
        inputValid.set(true);
      } else if (isAfter(dateToValidate, today)) {
        inputValid.set(false);
        errorMessage.set(t("components.dayPicker.datoForStor"));
      } else {
        inputValid.set(false);
        errorMessage.set(t("components.dayPicker.ugyldigDato"));
      }
    },
    [date, inputValid],
  );

  const handleOpen = useCallback(() => {
    showCalendar?.set(!showCalendar.value);
    inputValid.set(true);
  }, [showCalendar]);

  const handleSelect = useCallback(
    (selectedDate: Date | undefined) => {
      if (selectedDate) {
        validateDate(selectedDate);
      }
      handleOpen();
      inputRef.current?.blur();
    },
    [date, showCalendar, inputValid, validateDate],
  );

  const handleInputChange = useCallback(() => {
    if (!inputRef.current) return;

    const value = inputRef.current?.value.replace(/[^0-9\.]/g, "");
    const parsedDate = parse(value, dateFormatNorsk, new Date());
    if (isValid(parsedDate)) {
      validateDate(parsedDate);
    } else {
      inputValid.set(false);
    }
    inputValue.set(value);
  }, [date, validateDate]);

  return {
    handleOpen,
    handleSelect,
    handleInputChange,
    inputValid,
    inputRef,
    inputValue,
    errorMessage,
  };
}
